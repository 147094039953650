import { useLocation } from "react-router-dom";

export default function PromoToast() {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" &&
        <div
          className="promo-toast tw-rounded-xl tw-py-6 tw-px-6 filter-shadow-embossed tw-overflow-auto"
          style={{ textWrap: "nowrap" }}
        >
          <div className="tw-flex tw-flex-col tw-gap-2">
            <span className="tw-font-extrabold tw-text-6xl text-primary">PREVENTAS</span>
            <div className="tw-flex tw-flex-col">
              <div>SEDE EDGE <span className="tw-font-bold">BASADRE</span>: <span className="tw-text-sm">Av. Jorge Basadre 548, San Isidro.</span></div>
              <div>SEDE PRIME <span className="tw-font-bold">LA RAMBLA</span>: <span className="tw-text-sm">Jr. Ucello 176, San Borja.</span></div>
            </div>
            <div className="tw-h-full">
              <a
                href="https://api.whatsapp.com/send?phone=51938079688&text=¡Hola! Deseo información sobre la preventa de las nuevas Sedes Basadre y La Rambla"
                target="_blank" rel="noreferrer"
                className="tw-bg-black tw-text-white tw-font-bold tw-text-lg tw-py-2 tw-px-3 tw-rounded-lg tw-transition tw-duration-300 tw-ease-in-out tw-transform tw-hover:tw-opacity-80"
              >
                INFO AQUÍ
              </a>
            </div>

          </div>
        </div>
      }
    </>
  );
}
